import cx from 'classnames';

import * as styles from './spacing.module.less';

type Position = 'all' | 'left' | 'right' | 'top' | 'bottom' | 'vertical' | 'horizontal';
type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'mdl' | 'lg' | 'auto';

export type PositionSize = {
  [key in Size]?: Position[] | Position;
};

type SizeBase = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface Spacing {
  margins?: PositionSize;
  base?: SizeBase;
}

export interface SpacingProps {
  spacing?: Spacing;
}

export const useSpacing = (s: SpacingProps['spacing']) => {
  if (s?.margins) {
    const classes: any[] = [];
    const baseModifier = s?.base ? `base${s.base}` : '';
    Object.entries(s.margins).forEach(([size, pos]) => {
      if (pos && Array.isArray(pos)) {
        pos.forEach((p) => classes.push(styles[`${p}${size}${baseModifier}`]));
      } else if (pos) {
        classes.push(styles[`${pos}${size}${baseModifier}`]);
      }
    });
    return cx(classes);
  }
};
