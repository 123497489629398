/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum MembershipPackageRenewalPeriod {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}

export enum MembershipPackageStatus {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum OrganisationStatus {
  LAPSED = 'LAPSED',
  MEMBER = 'MEMBER',
  NONMEMBER = 'NONMEMBER',
}

export enum PackageYear {
  YEAR_2019_2020 = 'YEAR_2019_2020',
  YEAR_2020_2021 = 'YEAR_2020_2021',
  YEAR_2021_2022 = 'YEAR_2021_2022',
  YEAR_2022_2023 = 'YEAR_2022_2023',
  YEAR_2023_2024 = 'YEAR_2023_2024',
  YEAR_2024_2025 = 'YEAR_2024_2025',
}

export enum PaymentMethod {
  CARD = 'CARD',
  DIRECTDEBIT = 'DIRECTDEBIT',
  INVOICE = 'INVOICE',
  NOTSELECTED = 'NOTSELECTED',
}

export enum PaymentMethodAvailable {
  CARD = 'CARD',
  DIRECTDEBIT = 'DIRECTDEBIT',
  INVOICE = 'INVOICE',
}

export enum PaymentStatus {
  FAILED = 'FAILED',
  NOTPAID = 'NOTPAID',
  PAID = 'PAID',
  PENDING = 'PENDING',
}

export enum PricingOption {
  FIXED = 'FIXED',
  FLEXIBLE = 'FLEXIBLE',
}

export enum ProcessingFeeType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

/**
 * Member input
 */
export interface MemberInput {
  addedDate?: any | null;
  courtCount?: number | null;
  formSubmittedDate?: any | null;
  grassCourtCount?: number | null;
  isNew?: boolean | null;
  membershipPackageID?: string | null;
  orderID?: string | null;
  packageYear?: number | null;
  paymentDate?: any | null;
  paymentMethod?: PaymentMethod | null;
  paymentReference?: string | null;
  paymentStatus?: PaymentStatus | null;
  salesforceID?: string | null;
  status?: OrganisationStatus | null;
  totalCost?: number | null;
}

/**
 * Membership Package Input
 */
export interface MembershipPackageInput {
  benefits?: string | null;
  code?: string | null;
  confirmation?: string | null;
  courtCap?: number | null;
  courtCost?: number | null;
  description?: string | null;
  endDate?: any | null;
  gracePeriodDate?: any | null;
  grassCourtCost?: number | null;
  insurancePackageID?: string | null;
  isInsurancePackage?: boolean | null;
  maxPrice?: number | null;
  name?: string | null;
  organisationType?: string | null;
  packageYear?: number | null;
  paymentMethodAvailable?: PaymentMethodAvailable[] | null;
  pricingOption?: PricingOption | null;
  processingFee?: number | null;
  processingFeeType?: ProcessingFeeType | null;
  renewalPeriod?: MembershipPackageRenewalPeriod | null;
  renewsOn?: any | null;
  startDate?: any | null;
  status?: MembershipPackageStatus | null;
  type?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
