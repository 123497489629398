import defaultConfig from './default-config';
import { ClientConfig } from './types';

export const getEnvConfig = (): { [key: string]: string } => {
  if (typeof window !== 'undefined') {
    return window['__env__'];
  }
  return {};
};

export const getClientConfig = (): ClientConfig => {
  if (typeof window !== 'undefined') {
    const config = window['__client_config__'] ?? {};
    return { ...defaultConfig, ...stripNulls(config) };
  }
  return defaultConfig;
};

export const getRootProviderId = () => (typeof window !== 'undefined' ? (window as any).__provider__ : undefined);

const stripNulls = (obj: { [key: string]: any }) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
