import React, { useEffect, useState } from 'react';

import Spinner from 'src/components/spinner/spinner';
import { HEADER_ROOT_PROVIDER_ID } from 'src/constants/auth-headers';

// This header is added to responses in the ingress layer so it needs to be resolved in FE
const fetchProviderAndConfig = async () => {
  try {
    const providerRes = await fetch('/provider');
    const provider = providerRes?.headers.get(HEADER_ROOT_PROVIDER_ID);
    (window as any).__provider__ = provider;

    const configRes = await fetch(
      `/config?filters[organisationId]=${provider}&populate[siteConfig][on][site-config.control-centre][populate]=*`,
    );
    const json = await configRes.json();
    const config = json?.data?.[0]?.attributes?.siteConfig?.[0];
    (window as any).__client_config__ = config;
  } catch (e: any) {
    console.error(e?.message);
  }
};

export const ClientConfigProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [configLoaded, setConfigLoaded] = useState(false);
  useEffect(() => {
    fetchProviderAndConfig().then(() => setConfigLoaded(true));
  }, []);
  return <>{configLoaded ? children : <Spinner fullScreen />}</>;
};
