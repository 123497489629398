import { useMemo } from 'react';

import { ApolloClient, useQuery } from '@apollo/client';
import { GET_ORGANISATION_SLUG } from 'src/components/organisations/organisations-queries';
import { GetOrganisationSlug, GetOrganisationSlugVariables } from 'src/graphql-types/GetOrganisationSLug';
import { Venue } from 'src/hooks/use-section-and-districts';

import { meshGatewayClient } from './client';
import { GET_SELECTED_FACILITY, GET_VENUES } from './queries';

export const setSelectedFacility = (fac, client: ApolloClient<object>) => {
  client.writeQuery({
    query: GET_SELECTED_FACILITY,
    data: { selectedFacility: JSON.stringify(fac) },
  });
};

export const useSelectedFacility = () => {
  const { data: facilityData } = useQuery(GET_SELECTED_FACILITY);
  return useMemo(() => facilityData?.selectedFacility && JSON.parse(facilityData.selectedFacility), [facilityData]);
};

export const useSelectedFacilitysTenantAccessSettings = () => {
  const { Settings: tenantSettings } = useSelectedFacility() ?? {};
  return useMemo(
    () => ({
      isNationalAdminAccessEnabled: !!tenantSettings?.EnableNationalAdminAccess,
      isRegionalAdminAccessEnabled: !!tenantSettings?.EnableRegionalAdminAccess,
    }),
    [tenantSettings],
  );
};

export const useOrgId = () => useSelectedFacility()?.VenueID;

export const useOrgSlug = (orgId: string) => {
  const { data: organisation } = useQuery<GetOrganisationSlug, GetOrganisationSlugVariables>(GET_ORGANISATION_SLUG, {
    client: meshGatewayClient,
    variables: {
      organisationId: orgId,
    },
  });
  return useMemo(() => organisation?.getOrganisationById?.slug, [organisation]);
};

export const getOrgId = (client: ApolloClient<object>) => {
  const facilityData = client.readQuery({ query: GET_SELECTED_FACILITY });
  return facilityData?.selectedFacility && JSON.parse(facilityData.selectedFacility).VenueID;
};

export const useOrgName = () => useSelectedFacility()?.Name;

export const setVenues = (venues: Venue[], client: ApolloClient<object>) => {
  client.writeQuery({ query: GET_VENUES, data: { venues: JSON.stringify(venues) } });
};

export const useVenues = () => {
  const { data: venuesData } = useQuery(GET_VENUES);
  return useMemo(() => venuesData?.venues && JSON.parse(venuesData?.venues), [venuesData]);
};
