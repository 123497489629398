import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getEnvConfig } from 'src/config/config';

const { CLIENT_TIMEZONE = 'UTC' } = getEnvConfig() || {};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

dayjs.tz.setDefault(CLIENT_TIMEZONE);

export function getTimezoneAbbreviation(dateTime: dayjs.Dayjs, timeZone: string): string {
  try {
    const date: Date = dayjs.tz(dateTime, timeZone).toDate();
    const parts = new Intl.DateTimeFormat('en-US', {
      timeZone,
      timeZoneName: 'short',
    }).formatToParts(date);

    const timeZoneName = parts.find((part) => part.type === 'timeZoneName');
    return timeZoneName ? timeZoneName.value : '';
  } catch {
    return '';
  }
}
