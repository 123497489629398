import React from 'react';

import { Grid } from '@mui/material';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import Icon from 'src/components/icon/icon';
import { Body } from 'src/components/typography/typography';

import * as styles from './error-fallback.module.less';

export function ErrorFallback() {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" className={styles.errorContainer}>
      <Icon name="lg-empty-state" spacing={{ margins: { sm: 'bottom' } }} />
      <Body size="xxl" color={Body.color.SEMI_DARK_GREY}>
        {t('something went wrong')}
      </Body>
      <Button spacing={{ margins: { sm: 'top' } }} onClick={() => navigate('/')}>
        {t('go back')}
      </Button>
    </Grid>
  );
}
