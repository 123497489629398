import React, { ReactNode, useEffect } from 'react';

import { wrapRootBrowser } from './src/apollo/wrap-root';

import './src/utils/i18n';

import { Container, createRoot } from 'react-dom/client';

import './src/utils/i18n';
import './src/utils/dayjs';

export const wrapRootElement = wrapRootBrowser;

export const replaceHydrateFunction = () => {
  return (element: ReactNode, container: Container) => {
    const root = createRoot(container);
    root.render(element);
  };
};
