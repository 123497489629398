module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_yeiea4nj73lrdo7pqn6vh25thy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"control-centre","start_url":"/","icon":"src/images/cs-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6f375158c8a92a153e106c72ed45106f"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-use-query-params@1.0.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encodin_axnouqe35xg5dq6fiesck2mhlm/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_n44wsptvupjnbuw2whn42o23ey/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"DM Sans","file":"https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,700;1,9..40,300;1,9..40,400;1,9..40,500&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_eslint-plugin-jest@28.8.3_@t_mjm4opouqn4idhhnfqtsc3v5yq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
