// extracted by mini-css-extract-plugin
export var bodyBase = "level-colour-module--body-base--1cf1a level-colour-module--site-font--a4adb";
export var bodyLarge = "level-colour-module--body-large--4a820 level-colour-module--body-base--1cf1a level-colour-module--site-font--a4adb";
export var bodyLargeBold = "level-colour-module--body-large-bold--18b91 level-colour-module--body-base--1cf1a level-colour-module--site-font--a4adb";
export var bodyRegular = "level-colour-module--body-regular--547b9 level-colour-module--body-base--1cf1a level-colour-module--site-font--a4adb";
export var bodyRegularBold = "level-colour-module--body-regular-bold--3d908 level-colour-module--body-base--1cf1a level-colour-module--site-font--a4adb";
export var bodySmall = "level-colour-module--body-small--07d46 level-colour-module--body-base--1cf1a level-colour-module--site-font--a4adb";
export var bodySmallBold = "level-colour-module--body-small-bold--acdff level-colour-module--body-base--1cf1a level-colour-module--site-font--a4adb";
export var borderTop = "level-colour-module--border-top--091e6";
export var breakWordContainer = "level-colour-module--break-word-container--937ac";
export var buttonIconBase = "level-colour-module--button-icon-base--41c69";
export var clickLink = "level-colour-module--click-link--473c9";
export var complete = "level-colour-module--complete--b4b68 level-colour-module--text-inverted--b9eaa";
export var completeClick = "level-colour-module--complete-click--7c988";
export var dropdownBase = "level-colour-module--dropdown-base--21839";
export var dropdownSelectBase = "level-colour-module--dropdown-select-base--4f74f level-colour-module--text-input--fb86b";
export var flexCol = "level-colour-module--flex-col--14007";
export var formErrorMessage = "level-colour-module--form-error-message--3a1f5";
export var h3 = "level-colour-module--h3--08ba2";
export var h4 = "level-colour-module--h4--9d896";
export var hoverLink = "level-colour-module--hover-link--22765";
export var hoverRow = "level-colour-module--hover-row--68771";
export var link = "level-colour-module--link--d087b";
export var membershipContainer = "level-colour-module--membership-container--bb939 level-colour-module--flex-col--14007 level-colour-module--primary-border--17220";
export var membershipHeader = "level-colour-module--membership-header--4eb64";
export var membershipHeading = "level-colour-module--membership-heading--a467a";
export var membershipLabel = "level-colour-module--membership-label--ddef1";
export var moreFiltersBorderClass = "level-colour-module--more-filters-border-class--f0827";
export var pageBg = "level-colour-module--page-bg--761ed";
export var pending = "level-colour-module--pending--d14d5 level-colour-module--text-inverted--b9eaa";
export var pointer = "level-colour-module--pointer--319fc";
export var primary = "level-colour-module--primary--02442 level-colour-module--text-inverted--b9eaa";
export var primaryBorder = "level-colour-module--primary-border--17220";
export var primaryClick = "level-colour-module--primary-click--06534";
export var secondary = "level-colour-module--secondary--c0ea1";
export var secondaryClick = "level-colour-module--secondary-click--c3f41";
export var shadowBoxLight = "level-colour-module--shadow-box-light--2b716";
export var siteFont = "level-colour-module--site-font--a4adb";
export var slideDownAndFade = "level-colour-module--slideDownAndFade--59d6f";
export var slideLeftAndFade = "level-colour-module--slideLeftAndFade--f62a6";
export var slideRightAndFade = "level-colour-module--slideRightAndFade--6f987";
export var slideUpAndFade = "level-colour-module--slideUpAndFade--6abb8";
export var statusDecoration = "level-colour-module--status-decoration--43d29";
export var tertiary = "level-colour-module--tertiary--31e12";
export var tertiaryClick = "level-colour-module--tertiary-click--edbae";
export var textInput = "level-colour-module--text-input--fb86b";
export var textInverted = "level-colour-module--text-inverted--b9eaa";
export var textMediumDark = "level-colour-module--text-medium-dark--4b1b7";
export var tooltipFont = "level-colour-module--tooltipFont--d1604";
export var unstyledButton = "level-colour-module--unstyled-button--32ee2";
export var warning = "level-colour-module--warning--e314f level-colour-module--text-inverted--b9eaa";
export var warningClick = "level-colour-module--warning-click--c2ec9";
export var warningLink = "level-colour-module--warning-link--5ae85 level-colour-module--link--d087b";
export var warningOutlined = "level-colour-module--warning-outlined--deeef level-colour-module--text-inverted--b9eaa";