import graphql from 'graphql-tag';

export const GET_SELECTED_FACILITY = graphql`
  query GetSelectedFacility {
    selectedFacility @client
  }
`;

export const GET_VENUES = graphql`
  query GetVenues {
    venues @client
  }
`;
