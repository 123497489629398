import { createTheme } from '@mui/material';

export const muiThemeOverride = createTheme({
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(','),
  },
});

export default {
  'page-bg': '#f6f6f8',
  'primary-text-bg': '#fff',
  'primary-border-color': '#e9ebf0',
  'text-inverted': '#fff',

  'site-font': 'DM Sans, sans-serif',

  // Core brand colours
  'brand-cta-color': '#4b90d9', // call to actions & links
  'secondary-color': '#06143f', // charts or icons
  'accent-color-1': '#00a7e6', // charts or icons
  'accent-color-2': '#6e56f2', // charts or icons
  'accent-color-3': '#416dde',
  purple: '#3920c2',

  // Greys
  'dark-grey': '#3e424e', // main type colour, dark outlines or for dark buttons if required
  'semi-dark-grey': '#737680', // default icon colour - TBC on further use
  'mid-grey': '#9da1af', // inactive text
  'form-disabled-grey': '#dadce0', // form elements or for disabled buttons
  'divider-grey': '#eaecf1', // dividers, panel outlines or horizontal rules
  'background-grey': '#f6f6f8', // main page background and table header hover / selected states
  'secondary-background-grey': '#edf0f6',
  'element-background-grey': '#eaecf2',
  'core-greys-70': '#707485',
  white: '#ffffff', // background to cards, panels and the header
  'neutral-150': '#272f45',
  'neutral-5': '#F4F5F8',

  // Status Colours
  'error-color': '#d42f42', // negative status, alerts or warning. Also for error text
  'error-color-light': '#ed4d74',
  'pending-color': '#ffbc54', //  pending status, alert or warning
  'pending-color-alt': '#e95400', // pending status, alert or warning - Alternate version
  'pending-color-alt-dark': '#e98c00',
  'success-color': '#169e8f', // success status, alerts or messages. Also as a 3rd accent colour
  'selected-bg': '#f4f9ff', // selected status for pagination item
  'selected-border': '#9ab3ce', // selected status for pagination item
  'selected-border-dark': '#cbdcef', // selected status for pagination item
  'orange-color': '#dc5100',
  'warning-5': '#FDF6F2',
  'core-greys-100': '#323950',
  'grey-secondary-10': '#E7E8EA',
  'success-200': '#066D62',
  'success-10': '#E8F5F4',
  'highlight-200': '#5D20C2',
  'highlight-150': '#6c46d8',
  'highlight-10': '#F0EEFE',
  'info-10': '#E6EDFD',

  // System Notification
  'notification-error': '#c21a2d',
  'notification-success': '#00806b',
  'notification-info': '#2a3041',

  // Tints & lights
  'brand-color-10-pc': '#edf4fc', // background for selected/active cells in a table
  'error-color-10-pc': '#fff5f7', // background for error notification boxes or to highlight inline text
  'pending-color-10-pc': '#fffbed', // background for pending notification boxes or to highlight inline text
  'success-color-10-pc': '#f2fcfb', // background for success notification boxes
  'pending-color-20-pc': '#fff7dc', // - Use as a background for highlight inline text

  // Hover
  'brand-cta-color-hover': '#427fc0',

  // Click
  'brand-cta-color-click': '#35689d',

  //Gradients
  'success-gradient': '#0fc0b5',

  // Link
  'brand-cta-link': '#0026AF',
};
