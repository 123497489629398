import * as Sentry from '@sentry/gatsby';

import { getEnvConfig } from './config';

export default function sentrySetup() {
  Sentry.init({
    dsn: getEnvConfig().REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /\/graphql/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: getEnvConfig().REACT_APP_ENVIRONMENT || 'testing',
  });
}
