import gql from 'graphql-tag';

export const GET_ORG_HIERARCHY = gql`
  query GetOrgHierarchy($orgId: UUID!) {
    organisation(id: $orgId) {
      id
      hierarchy
    }
  }
`;

export const GET_SANCTIONING_HIERARCHY = gql`
  query GetSanctioningHierarchy($tournamentId: UUID!) {
    tournament(id: $tournamentId) {
      id
      sanctionApprovalHierarchy {
        id
      }
    }
  }
`;
